body {
  background-color: rgb(201, 198, 198);
}

h1 {
  border: 0;
  margin: 0;
  padding: 10px;
}

.ebook {
  width: 200px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.format {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.gift {
  text-decoration: underline;
  color: rgb(255, 0, 0);
}

.video {
  width: 400px;
}

