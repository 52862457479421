a {
  color: black;
  padding: 10px;
}

body {
  background-color: rgb(201, 198, 198);
}

h1 {
  border: 0;
  margin: 0;
  padding: 10px;
  text-align: center;
}

.ebook {
  width: 200px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
  justify-content: center;
  align-items: center;
}

.format h2 {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100vw;
}

.logo {
  width: 40vw;
}

p {
  text-align: center;
}

span {
  text-decoration: underline;
  color: magenta;
}

.thumbnail {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.video {
  aspect-ratio: 16 / 9;
  width: 100%;
  padding: 10px;
}

/* Sign Up Form CSS */

#af-form-531112897 {
  border-radius: 6px;
  box-shadow: 0 24px 50px 0 rgba(0, 0, 0, 0.12), 0 5px 15px rgba(0, 0, 0, 0.07);
}

/*
#af-form-531112897 .af-body {

} */

#af-form-531112897 .af-body .bodyText p {
  line-height: 1.5;
  margin: 0 0 1rem;
}

#af-form-531112897 .af-body .bodyText p:last-child {
  margin: 0;
}

#af-form-531112897 .af-header {
  border-radius: 6px 6px 0 0;
}
#af-form-531112897 .af-footer {
  border-radius: 0 0 6px 6px;
}
/* #af-form-531112897 .af-footer p {
} */

#af-form-531112897 .af-body .af-element {
  margin-top: 1rem !important;
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
}

#af-form-531112897 .af-body .af-textWrap {
  width: 100%;
}

#af-form-531112897 .af-body .af-element:first-child {
  margin-top: 0 !important;
}
#af-form-531112897 .af-body label.previewLabel {
  font-weight: 700 !important;
  margin-bottom: 0.25rem !important;
}
#af-form-531112897 .af-body input.text {
  border-radius: 13px;
  box-sizing: border-box !important;
  margin-top: 0.25rem !important;
  padding: 12px 8px !important;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
#af-form-531112897 .af-body input.text:focus {
  outline: none;
}
#af-form-531112897 .af-body select {
  width: 100%;
}
#af-form-531112897 .af-body .af-dateWrap select {
  width: 33%;
}
#af-form-531112897 .choiceList-radio-stacked {
  width: 100% !important;
}
#af-form-531112897 .af-body .af-element-radio,
#af-form-531112897 .af-body .af-element-checkbox {
  margin: 10px 0 !important;
  margin-top: 10px !important;
}
#af-form-531112897 .af-element-radio:last-child,
#af-form-531112897 .af-element-checkbox:last-child {
  margin: 10px 0 0 !important;
}

#af-form-531112897 .af-element-radio input.radio,
#af-form-531112897 .af-element-checkbox input.checkbox {
  display: inline;
  height: 0;
  opacity: 0;
  overflow: hidden;
  width: 0;
}

#af-form-531112897 .af-element-radio label.choice,
#af-form-531112897 .af-element-checkbox label.choice {
  background: transparent;
  border-radius: 4px;
  display: block !important;
  font-weight: 300 !important;
  margin-left: 30px;
  padding: 5px !important;
  position: relative;
  transition-duration: 0.2s;
}
#af-form-531112897 .af-element-radio input.radio:focus ~ label,
#af-form-531112897 .af-element-checkbox input.checkbox:focus ~ label {
  box-shadow: inset 0 0 0 2px rgba(25, 35, 70, 0.25);
}
#af-form-531112897 .af-element-radio input.radio:checked ~ label,
#af-form-531112897 .af-element-checkbox input.checkbox:checked ~ label {
  background: rgba(25, 35, 70, 0.05);
}

#af-form-531112897 .af-element-radio label.choice:before,
#af-form-531112897 .af-element-checkbox label.choice:before {
  background-color: rgb(201, 198, 198);
  border: 1px solid #717379;
  border-radius: 50%;
  display: inline-block;
  content: "";
  /* float: left; */
  height: 1em;
  margin-left: -30px;
  margin-right: 0.5rem;
  position: relative;
  width: 1em;
}
#af-form-531112897 .af-element-checkbox label.choice:before {
  border-radius: 4px !important;
}
#af-form-531112897 .af-element-radio input.radio:checked ~ label:before,
#af-form-531112897 .af-element-checkbox input.checkbox:checked ~ label:before {
  animation: rotateIn 0.2s ease-in;
  background-color: transparent;
  border-color: transparent;
  content: "✔️";
  top: -4px;
}

#af-form-531112897 .af-element-radio input.radio:checked ~ label:before {
  content: "⚫";
}

#af-form-531112897 .af-selectWrap {
  margin-top: 10px;
}
#af-form-531112897 .af-selectWrap select {
  background: rgb(201, 198, 198);
}

#af-form-531112897 .buttonContainer {
  box-sizing: border-box !important;
}
#af-form-531112897 input.submit,
#af-form-531112897 #webFormSubmitButton {
  /* -webkit-appearance: none; */
  border-color: transparent;
  border-radius: 4px;
  box-shadow: 0 16px 30px 0 rgba(0, 0, 0, 0.1), 0 5px 10px rgba(0, 0, 0, 0.05);
  display: inline-block;
  font-weight: 700;
  letter-spacing: 0.025rem;
  line-height: 40px;
  padding: 0 25px;
  text-decoration: none;
  transition: all 0.3s ease;
  white-space: nowrap;
}
#af-form-531112897 input.submit:hover,
#af-form-531112897 #webFormSubmitButton:hover {
  cursor: pointer;
}
#af-form-531112897 input.text {
  background-image: none !important;
  border-radius: 3px !important;
  margin-top: 0.5rem !important;
  padding: 0.6rem 2.5rem !important;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  width: 100% !important;
}
#af-form-531112897 input.text:hover {
  cursor: pointer;
  opacity: 0.9;
}

#af-form-531112897 .af-body .poweredBy,
#af-form-531112897 .af-body .privacyPolicy {
  margin: 15px 0 0 !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.poweredBy a,
.privacyPolicy p {
  font-size: 0.75rem !important;
}

@keyframes rotateIn {
  0% {
    opacity: 0;
    transform: translateY(10px) rotate(-90deg);
  }
  80% {
    transform: translateY(-1px) rotate(10deg);
  }
  100% {
    opacity: 1;
    transform: translateY(0) rotate(0);
  }
}
